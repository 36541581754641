import SEO from '@components/Layout/SEO'
import SplashScreen from '@components/SplashScreen'
import Welcome from '@components/Welcome/Welcome'
import useCurrentUser from '@hooks/useCurrentUser'
import { AnimatePresence } from 'framer-motion'
import { GetServerSideProps } from 'next'
import { useRouter } from 'next/router'
import { FC, useEffect } from 'react'
import { QueryClient } from 'react-query'
import { dehydrate } from 'react-query/hydration'
import { unsealUser } from '../apiUtils/cookie'

const Home: FC = () => {
	const router = useRouter()
	const { data: user, isLoading, isError } = useCurrentUser()

	useEffect(() => {
		if (user) router.replace('/claim')
	}, [user, router])

	if (isLoading || user)
		return (
			<SEO>
				<SplashScreen />
			</SEO>
		)

	if (!user || isError) {
		return (
			<SEO>
				<AnimatePresence>
					<Welcome key="Welcome" />
				</AnimatePresence>
			</SEO>
		)
	}
}

export const getServerSideProps: GetServerSideProps = async ({ req }) => {
	const queryClient = new QueryClient()

	await queryClient.prefetchQuery('current_user', () => unsealUser(req))

	return {
		props: { dehydratedState: JSON.parse(JSON.stringify(dehydrate(queryClient))) },
	}
}

export default Home
